<template>
  <div
    class="min-h-screen"
    :class="[
      $theme.baseBg,
      [
        'setting',
        'stats',
        'home',
        'notifications',
        'signals',
        'language',
        'orders',
        'refs',
        'faq',
        'tariff',
        'profile',
        'security',
        'show-signal',
        'ref-stats',
        'incomplete',
        'terms'
      ].includes($route.name) ? $theme.secondBaseBg : '',
      $theme.baseTextColor,
    ]"
  ><router-view></router-view></div>
  <template v-if="isShowNavbar">
    <AppNavbar />
  </template>
  <template v-if="$settings.support_links">
    <SupportWidget :links="$settings.support_links" />
  </template>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import SupportWidget from "@/components/SupportWidget.vue";

export default {
  name: 'App',
  components: {
    SupportWidget,
    AppNavbar,
  },
  computed: {
    isShowNavbar() {
      return (this.$user.reg_status === 'COMPLETED' || this.$user.reg_status === 'SKIP_SETUP')
          //&& this.$user.app_status !== 'CHOICE_LANG'
          //&& this.$user.app_status !== 'CONTACT_DETAILS_INCOMPLETE'
          && this.$route.name !== 'two-factor-auth';
    }
  },
  data() {
    return {
      telegramReady: false,
    }
  },
  mounted() {
    if (window.Telegram && window.Telegram.WebApp) {
      this.telegramReady = true

      if (window.Telegram.WebApp.isVersionAtLeast('7.0')) {
        window.Telegram.WebApp.SettingsButton.show();

        window.Telegram.WebApp.SettingsButton.onClick(() => {
          console.log('Settings button clicked!');
          this.$router.push({ name: 'setting' });
        });
      }
    }
  },
}
</script>
<script setup>
</script>