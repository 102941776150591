<template>
  <div :class="[userAppStatus === 'VIEW_TOUR' ? 'min-h-screen' : 'px-4 pt-5 pb-10']">
    <template v-if="userAppStatus === 'VIEW_TOUR'">
      <GuideTour @action-success="onSuccessFinishGuide" />
    </template>
    <template v-else>
      <div class="opacity-50 flex items-center justify-between text-sm mb-1">
        {{ progressMessage }}
        <div>
          {{ progressPercent }}%
        </div>
      </div>
      <div class="w-full rounded-full h-2.5 mb-5" :class="[$theme.placeBg]">
        <div class="h-2.5 rounded-full" :class="[$theme.inverseBaseBg]" :style="`width: ${progressPercent}%`"></div>
      </div>
      <div v-for="systemTourItem in systemTour" :key="systemTourItem.id">
        <template v-if="userAppStatus === 'CHOICE_LANG' && systemTourItem.component === 'CHOICE_LANG'">
          <div class="px-4 py-2 rounded-xl" :class="[$theme.cardBg]">
            <a @click="onChangeLanguage(language)" href="javascript:void(0)"
               v-for="(language, langIndex) in languages" :key="langIndex"
               :class="[
            'flex justify-between items-center py-2',
            langIndex === (languages.length - 1) ? '' : `border-b ${$theme.cardBorderColor}`
          ]"
            >
              <div class="flex flex-col">
                <div>{{ language.title }}</div>
                <div class="text-xs" :class="[$theme.cardLabelTextColor]">{{ language.native_title }}</div>
              </div>
              <div v-if="language.language_code === $user.language_code">
                <CheckCircleIcon class="w-5" :class="[$theme.navActiveTextColor2]" />
              </div>
            </a>
          </div>
          <div class="mt-4">
            <button
                @click="onApplyLang"
                type="button"
                class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
                :class="[
                  isFormProcessing ? 'opacity-50 pointer-events-none' : '',
                  $theme.inverseBaseBg,
                ]"
            >
              <template v-if="isFormProcessing">
                <ButtonSpinner />
              </template>
              {{ $t('choice_lang') }}
            </button>
          </div>
        </template>
        <template v-if="userAppStatus === 'PENDING_TERMS_ACCEPTANCE' && systemTourItem.component === 'PENDING_TERMS_ACCEPTANCE'">
          <div>
            <div class="px-4 py-3 rounded-xl" :class="[$theme.cardBg]">
              <div class="text-2xl font-semibold">{{ $t('accept_terms') }}</div>
              <div class="text-sm mt-2" :class="[$theme.cardLabelTextColor]">
                {{ $t('accept_terms_desc') }}
              </div>

              <dd class="mt-4 text-sm" :class="[$theme.baseTextColor]">
                <ul role="list" class="divide-y rounded-xl border" :class="[$theme.cardDivideColor1, $theme.cardBorderColor]">
                  <li v-for="document in documents" :key="document.id" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                    <div class="flex w-0 flex-1 items-center">
                      <PaperClipIcon class="h-5 w-5 flex-shrink-0" :class="[$theme.cardLabelTextColor]" aria-hidden="true" />
                      <div class="ml-4 flex min-w-0 flex-1 gap-2">
                        <span class="truncate font-medium">{{ document.title }}</span>
                      </div>
                    </div>
                    <div class="ml-3 flex-shrink-0">
                      <a :href="document.file" target="_blank" class="font-medium" :class="[$theme.navActiveTextColor2]">{{ $t('view') }}</a>
                    </div>
                  </li>
                </ul>
              </dd>

              <div class="mt-4 relative flex items-start">
                <div class="flex h-6 items-center">
                  <input
                    v-model="termsForm.accept"
                    id="apply_terms"
                    type="checkbox"
                    class="h-4 w-4 rounded"
                    :class="[$theme.navActiveTextColor2, $theme.inputRingFocusColor, $theme.inputBorderColor, $theme.checkboxBgInactive]"
                  />
                </div>
                <div class="ml-3 text-sm leading-6">
                  <label for="apply_terms" class="font-medium" :class="[$theme.baseTextColor]">{{ $t('apply_terms') }}</label>
                  <p id="comments-description" class="text-sm" :class="[$theme.cardLabelTextColor]">{{ $t('apply_terms_desc') }}</p>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <button
                  @click="onApplyTerms"
                  type="button"
                  class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
                  :class="[
                    isFormProcessing || !termsForm.accept ? 'opacity-50 pointer-events-none' : '',
                    $theme.inverseBaseBg,
                  ]"
              >
                <template v-if="isFormProcessing">
                  <ButtonSpinner />
                </template>
                {{ $t('apply_terms') }}
              </button>
            </div>
          </div>
        </template>
        <template v-if="userAppStatus === 'CONTACT_DETAILS_INCOMPLETE' && systemTourItem.component === 'CONTACT_DETAILS_INCOMPLETE'">
          <div>
            <div class="rounded-xl px-4 pt-3 pb-4" :class="[$theme.cardBg]">
              <div class="text-3xl font-semibold">{{ $t('fill_contacts') }}</div>

              <div class="mt-4">
                <div>
                  <label for="email" class="block text-sm font-medium leading-6" :class="[$theme.baseTextColor]">E-mail</label>
                  <div class="mt-2">
                    <input
                      v-model="contactForm.email"
                      id="email"
                      type="email"
                      class="block w-full rounded-xl border-0 py-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                      :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                      ]"
                    />
                    <p v-if="emailError" class="text-red-500 text-sm mt-1">{{ emailError }}</p>
                  </div>
                </div>
                <div class="mt-3">
                  <PhoneInput v-model="contactForm.phone" />
                  <p v-if="phoneError" class="text-red-500 text-sm mt-1">{{ phoneError }}</p>
                </div>
              </div>
            </div>

            <div class="mt-5">
              <button
                @click="updateContacts"
                type="button"
                class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
                :class="[
                  isFormProcessing ? 'opacity-50 pointer-events-none' : '',
                  $theme.inverseBaseBg,
                ]"
              >
                <template v-if="isFormProcessing">
                  <ButtonSpinner />
                </template>
                {{ $t('save_contacts') }}
              </button>

              <template v-if="systemTourItem.skip">
                <div class="mt-5">
                  <button
                    @click="onSkipStep('EXCHANGE_NOT_CONNECTED')"
                    class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold"
                    :class="[$theme.placeBg, $theme.cardLabelTextColor]"
                  >
                    {{ $t('skip') }}
                  </button>
                </div>
              </template>
            </div>
          </div>
        </template>
        <template v-if="userAppStatus === 'EXCHANGE_NOT_CONNECTED' && systemTourItem.component === 'EXCHANGE_NOT_CONNECTED'">
          <div>
            <ConnectExchangeAccount @action-success="onSuccessConnectAccount" />
            <template v-if="systemTourItem.skip">
              <div class="mt-5">
                <button
                  @click="onSkipStep('PLAN_NOT_SELECTED')"
                  class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold"
                  :class="[$theme.placeBg, $theme.cardLabelTextColor]"
                >
                  {{ $t('skip') }}
                </button>
              </div>
            </template>
          </div>
        </template>
        <template v-if="userAppStatus === 'PLAN_NOT_SELECTED' && systemTourItem.component === 'PLAN_NOT_SELECTED'">
          <SelectTariff />
          <template v-if="systemTourItem.skip">
            <div>
              <button
                  @click="onSkipStep('NO_SIGNALS_SELECTED')"
                  class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold"
                  :class="[$theme.placeBg, $theme.cardLabelTextColor]"
              >
                {{ $t('skip') }}
              </button>
            </div>
          </template>
        </template>
        <template v-if="userAppStatus === 'NO_SIGNALS_SELECTED' && systemTourItem.component === 'NO_SIGNALS_SELECTED'">
          <div>
            <SelectSignals />
            <div class="mt-5">
              <button
                  @click="completeSetup"
                  type="button"
                  class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
                  :class="[isFormProcessing ? 'opacity-50 pointer-events-none' : '', $theme.inverseBaseBg]"
              >
                <template v-if="isFormProcessing">
                  <ButtonSpinner />
                </template>
                {{ $t('complete_setup') }}
              </button>
              <template v-if="systemTourItem.skip">
                <div class="mt-5">
                  <button
                      @click="onSkipStep('COMPLETED')"
                      class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold"
                      :class="[$theme.placeBg, $theme.cardLabelTextColor]"
                  >
                    {{ $t('skip') }}
                  </button>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import GuideTour from "@/components/GuideTour.vue";
import { PaperClipIcon } from "@heroicons/vue/24/outline";
import ButtonSpinner from "@/components/ButtonSpinner.vue";
import PhoneInput from "@/components/form/PhoneInput.vue";
import { validatePhoneNumber } from "@/helpers/validationHelper";
import {CheckCircleIcon} from "@heroicons/vue/20/solid";
import ConnectExchangeAccount from "@/components/exchange/ConnectExchangeAccount.vue";
import SelectTariff from "@/components/tariff/SelectTariff.vue";
import SelectSignals from "@/components/settings/SelectSignals.vue";

export default {
  components: {
    GuideTour,
    CheckCircleIcon,
    ButtonSpinner,
    PaperClipIcon,
    PhoneInput,
    ConnectExchangeAccount,
    SelectTariff,
    SelectSignals
  },

  data() {
    return {
      isFormProcessing: false,
      userAppStatus: this.$user.app_status,
      termsForm: {
        accept: false,
      },
      contactForm: {
        email: '',
        phone: ''
      },
      emailError: '',
      phoneError: '',

      languages: [],
      documents: [],
      systemTour: [],
    }
  },

  mounted() {
    this.loadSystemTours();

    this.loadLanguages();
    this.loadDocuments();
    this.checkStatus();
  },

  watch: {
    userAppStatus(newStatus) {
      console.log(newStatus);
      this.checkStatus();
    }
  },

  computed: {
    currentSystemTour() {
      for (const item of this.systemTour) {
        if (this.userAppStatus === item.component) {
          return item;
        }
      }

      return null;
    },

    progressPercent() {
      if (this.userAppStatus === 'CHOICE_LANG') {
        return 0;
      }

      if (this.userAppStatus === 'PENDING_TERMS_ACCEPTANCE') {
        return 10;
      }

      if (this.userAppStatus === 'CONTACT_DETAILS_INCOMPLETE') {
        return 20;
      }

      if (this.userAppStatus === 'EXCHANGE_NOT_CONNECTED') {
        return 40;
      }

      if (this.userAppStatus === 'PLAN_NOT_SELECTED') {
        return 60;
      }

      if (this.userAppStatus === 'NO_SIGNALS_SELECTED') {
        return 80;
      }

      return 0;
    },
    progressMessage() {
      if (!this.currentSystemTour) {
        return '';
      }

      return this.currentSystemTour.title;
    }
  },

  methods: {
    loadSystemTours() {
      this.$api.get('/system-tour').then((result) => {
        console.log(result.data.data)
        this.systemTour = result.data.data;
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    checkStatus() {
      this.checkCompleteSetup();
    },

    onChangeLanguage(language) {
      this.$api.post('/user/update-language', {
        language_code: language.language_code
      }).then((result) => {
        console.log('Settings updated.', result);

        this.$i18n.locale = language.language_code;
        this.$user.language_code = language.language_code;
      }).catch(e => {
        alert(e.message);
        this.error = e;
        this.processing = null;
      });
    },

    onApplyLang() {
      this.isFormProcessing = true;

      this.$api.post('/user/apply-lang').then(result => {
        console.log('result', result);
        this.userAppStatus = 'PENDING_TERMS_ACCEPTANCE';
        this.isFormProcessing = false;
      });
    },

    onApplyTerms() {
      this.isFormProcessing = true;

      if (this.termsForm.accept) {
        this.$api.post('/user/apply-terms').then(result => {
          console.log('result', result);
          this.userAppStatus = 'CONTACT_DETAILS_INCOMPLETE';
          this.isFormProcessing = false;
        });
      }
    },

    onSuccessConnectAccount() {
      this.userAppStatus = 'PLAN_NOT_SELECTED';
    },

    onSuccessFinishGuide() {
      this.userAppStatus = 'CHOICE_LANG';
    },

    loadLanguages() {
      this.$api.get('/languages').then((result) => {
        console.log(result.data.data)
        this.languages = result.data.data;
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadDocuments() {
      this.$api.get('/documents?bot_id=1').then(result => {
        this.documents = result.data.data;
      });
    },

    validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    },

    updateContacts() {
      this.isFormProcessing = true;

      this.emailError = '';
      this.phoneError = '';

      if (!this.validateEmail(this.contactForm.email)) {
        this.emailError = this.$t('invalid_email');
        this.isFormProcessing = false;
        return;
      }

      if (!validatePhoneNumber(this.contactForm.phone)) {
        this.isFormProcessing = false;
        return;
      }

      this.$api.post('/user/update-contacts', this.contactForm).then(() => {
        this.userAppStatus = 'EXCHANGE_NOT_CONNECTED';
        this.isFormProcessing = false;
      });
    },

    onSkipStep(status) {
      this.$api.post('/user/skip-tour-step', {
        status
      }).then(() => {
        this.userAppStatus = status;
      });
    },

    completeSetup() {
      this.isFormProcessing = true;

      this.$api.post('/user/complete-setup', {}).then(() => {
        window.location.href = '/';
      });
    },

    checkCompleteSetup() {
      this.$api.post('/user/check-complete-setup', {}).then((result) => {
        this.userAppStatus = result.data.status;

        if (this.userAppStatus === 'COMPLETED') {
          window.location.href = '/';
        }
      });
    }
  }
}
</script>

