<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-5">{{ $t('settings') }}</h3>
    <template v-if="loading">
      <div class="rounded-lg" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <DisconnectExchangeAlert :exchanges="exchanges" />

      <div class="mb-3 rounded-lg" :class="[$theme.cardBg]">
        <div>
          <router-link to="/setting/profile" class="flex items-center justify-between px-2 py-2">
            <div class="flex items-center">
              <div class="w-7 h-7 bg-rose-500 text-white flex items-center justify-center rounded-lg">
                <UserIcon class="w-5" />
              </div>
              <span class="ml-2">{{ $t('my_profile') }}</span>
            </div>
            <div class="flex items-center text-sm" :class="[$theme.cardLabelTextColor]">
              <ChevronRightIcon class="w-5" />
            </div>
          </router-link>
        </div>
      </div>

      <div class="rounded-lg" :class="[$theme.cardBg]">
        <div v-for="exchange in exchanges" :key="exchange.id" class="border-b" :class="[$theme.cardBorderColor]">
          <router-link :to="`/exchange/${exchange.id}`" class="flex items-center justify-between px-3 py-2">
            <div class="flex items-center">
              <template v-if="exchange.exchange_slug === 'binance'">
                <img src="/img/binance.png" class="w-6 mr-2" />
                Binance
              </template>
              <template v-if="exchange.exchange_slug === 'okx'">
                <img src="/img/okx.png" class="w-6 mr-2" />
                OKX
              </template>
              <template v-if="exchange.exchange_slug === 'bybit'">
                <img src="/img/bybit.png" class="w-6 mr-2 rounded-full" />
                Bybit
              </template>
              <template v-if="exchange.exchange_slug === 'mexc'">
                <img src="/img/mexc.png" class="w-6 mr-2 rounded-full" />
                Mexc
              </template>
              <span class="ml-2" :class="[$theme.cardLabelTextColor]">ID: {{ exchange.id }}</span>
            </div>
            <div>
              <template v-if="!exchange.status_connected">
                <ExclamationTriangleIcon class="w-5 h-5" :class="[$theme.navActiveTextColor2]"></ExclamationTriangleIcon>
              </template>
            </div>
          </router-link>
        </div>
        <router-link to="/create-exchange" class="flex items-center px-3 py-2" :class="[$theme.navActiveTextColor2]">
          <div class="flex items-center">
            <PlusIcon class="w-6 mr-2" /> {{ $t('add_exchange_account') }}
          </div>
        </router-link>
      </div>

      <div class="mt-3">
        <div class="rounded-lg" :class="[$theme.cardBg]">
          <div class="border-b" :class="[$theme.cardBorderColor]">
            <router-link to="/setting/signals" class="flex items-center justify-between px-2 py-2">
              <div class="flex items-center">
                <div class="w-7 h-7 bg-green-500 text-white flex items-center justify-center rounded-lg">
                  <SignalIcon class="w-5" />
                </div>
                <span class="ml-2">{{ $t('signals') }}</span>
              </div>
              <div class="flex items-center text-sm" :class="[$theme.cardLabelTextColor]">
                <ChevronRightIcon class="w-5" />
              </div>
            </router-link>
          </div>
          <div class="border-b" :class="[$theme.cardBorderColor]">
            <router-link to="/setting/notifications" class="flex items-center justify-between px-2 py-2">
              <div class="flex items-center">
                <div class="w-7 h-7 bg-red-500 text-white flex items-center justify-center rounded-lg">
                  <BellIcon class="w-5" />
                </div>
                <span class="ml-2">{{ $t('notifications') }}</span>
              </div>
              <div class="flex items-center text-sm" :class="[$theme.cardLabelTextColor]">
                <ChevronRightIcon class="w-5" />
              </div>
            </router-link>
          </div>
          <div class="border-b" :class="[$theme.cardBorderColor]">
            <router-link to="/setting/language" class="flex items-center justify-between px-2 py-2">
              <div class="flex items-center">
                <div class="w-7 h-7 bg-purple-600 text-white flex items-center justify-center rounded-lg">
                  <LanguageIcon class="w-5" />
                </div>
                <span class="ml-2">{{ $t('language') }}</span>
              </div>
              <div class="flex items-center" :class="[$theme.cardLabelTextColor]">
                {{ selectedLanguageTitle }}
                <ChevronRightIcon class="w-5" />
              </div>
            </router-link>
          </div>
          <div>
            <router-link to="/setting/security" class="flex items-center justify-between px-2 py-2">
              <div class="flex items-center">
                <div class="w-7 h-7 bg-gray-600 text-white flex items-center justify-center rounded-lg">
                  <LockClosedIcon class="w-5" />
                </div>
                <span class="ml-2">{{ $t('security') }}</span>
              </div>
              <div class="flex items-center text-sm" :class="[$theme.cardLabelTextColor]">
                <ChevronRightIcon class="w-5" />
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="mt-3 rounded-lg" :class="[$theme.cardBg]">
        <div>
          <router-link to="/terms" class="flex items-center justify-between px-2 py-2">
            <div class="flex items-center">
              <div class="w-7 h-7 bg-gray-200 text-black flex items-center justify-center rounded-lg">
                <DocumentTextIcon class="w-5" />
              </div>
              <span class="ml-2">{{ $t('terms') }}</span>
            </div>
            <div class="flex items-center text-sm" :class="[$theme.cardLabelTextColor]">
              <ChevronRightIcon class="w-5" />
            </div>
          </router-link>
        </div>
      </div>

      <template v-if="$settings.support_links">
        <div class="mt-3 rounded-lg" :class="[$theme.cardBg]">
          <div v-for="(supportLink, supportLinkIdx) in $settings.support_links" :key="supportLinkIdx" :class="[
            (supportLinkIdx) !== ($settings.support_links.length - 1) ? 'border-b ' + $theme.cardBorderColor : ''
          ]">
            <a :href="supportLink.url" class="flex items-center justify-between px-2 py-2">
              <div class="flex items-center">
                <div class="w-7 h-7 bg-gray-200 text-black flex items-center justify-center rounded-lg">
                  <QuestionMarkCircleIcon class="w-5" />
                </div>
                <span class="ml-2">{{ supportLink.title }}</span>
              </div>
              <div class="flex items-center text-sm" :class="[$theme.cardLabelTextColor]">
                <ChevronRightIcon class="w-5" />
              </div>
            </a>
          </div>
        </div>
      </template>

      <div v-if="user && parseInt(user.id) === 1" class="mt-6">
        <router-link to="/logs" class="block px-6 py-3 bg-gray-200 text-red-500 rounded-lg text-center">System logs</router-link>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue'
import {
  PlusIcon,
  LanguageIcon,
  BellIcon,
  ChevronRightIcon,
  SignalIcon,
  UserIcon,
  LockClosedIcon,
  DocumentTextIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/vue/24/outline'
import {ExclamationTriangleIcon} from "@heroicons/vue/24/solid";
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import DisconnectExchangeAlert from "@/components/elem/DisconnectExchangeAlert.vue";

export default {
  components: {
    DisconnectExchangeAlert,
    PlusIcon,
    LanguageIcon,
    LoadingBlock,
    BellIcon,
    ChevronRightIcon,
    SignalIcon,
    UserIcon,
    LockClosedIcon,
    ExclamationTriangleIcon,
    DocumentTextIcon,
    QuestionMarkCircleIcon
  },

  computed: {
    selectedLanguageTitle() {
      for (const lang of this.languages) {
        if (this.$user.language_code === lang.language_code) {
          return lang.native_title;
        }
      }

      return '';
    }
  },

  methods: {
    loadLanguages() {
      this.$api.get('/languages').then((result) => {
        console.log(result.data.data)
        this.languages = result.data.data;
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadExchanges() {
      this.$api.get('/exchanges').then((result) => {
        this.exchanges = result.data.data
        this.loading = false
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    }
  },

  data() {
    return {
      loading: true,
      user: null,
      exchanges: [],
      languages: [],
      error: null,
    }
  },

  mounted() {
    console.log('Setting View.');

    this.loadExchanges();
    this.loadLanguages();
    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>